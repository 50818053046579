<template>
  <div class="bg-white">
    <the-app-bar/>
    <div class="container py-20">
    <div class="row">
      <div class="col-md-12">
        <h4>Support Maya Recovery Today:</h4>
      </div>
      <div class="col-md-7" >
        <img src="https://via.placeholder.com/850x600" alt="" />

        <nav class="my-4">
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <a
              class="nav-link active"
              id="nav-home-tab"
              data-toggle="tab"
              href="#nav-home"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
              >About</a
            >
            <a
              class="nav-link"
              id="nav-profile-tab"
              data-toggle="tab"
              href="#nav-profile"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
              >Comments</a
            >
            <a
              class="nav-link"
              id="nav-contact-tab"
              data-toggle="tab"
              href="#nav-contact"
              role="tab"
              aria-controls="nav-contact"
              aria-selected="false"
              >Updates</a
            >
          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
          <div
            class="tab-pane fade show active"
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
            Nulla est ullamco ut irure incididunt nulla Lorem Lorem minim irure
            officia enim reprehenderit. Magna duis labore cillum sint
            adipisicing exercitation ipsum. Nostrud ut anim non exercitation
            velit laboris fugiat cupidatat. Commodo esse dolore fugiat sint
            velit ullamco magna consequat voluptate minim amet aliquip ipsum
            aute laboris nisi. Labore labore veniam irure irure ipsum pariatur
            mollit magna in cupidatat dolore magna irure esse tempor ad mollit.
            Dolore commodo nulla minim amet ipsum officia consectetur amet
            ullamco voluptate nisi commodo ea sit eu.
          </div>
          <div
            class="tab-pane fade"
            id="nav-profile"
            role="tabpanel"
            aria-labelledby="nav-profile-tab"
          >
            Nulla est ullamco ut irure incididunt nulla Lorem Lorem minim irure
            officia enim reprehenderit. Magna duis labore cillum sint
            adipisicing exercitation ipsum. Nostrud ut anim non exercitation
            velit laboris fugiat cupidatat. Commodo esse dolore fugiat sint
            velit ullamco magna consequat voluptate minim amet aliquip ipsum
            aute laboris nisi. Labore labore veniam irure irure ipsum pariatur
            mollit magna in cupidatat dolore magna irure esse tempor ad mollit.
            Dolore commodo nulla minim amet ipsum officia consectetur amet
            ullamco voluptate nisi commodo ea sit eu.
          </div>
          <div
            class="tab-pane fade"
            id="nav-contact"
            role="tabpanel"
            aria-labelledby="nav-contact-tab"
          >
            Nulla est ullamco ut irure incididunt nulla Lorem Lorem minim irure
            officia enim reprehenderit. Magna duis labore cillum sint
            adipisicing exercitation ipsum. Nostrud ut anim non exercitation
            velit laboris fugiat cupidatat. Commodo esse dolore fugiat sint
            velit ullamco magna consequat voluptate minim amet aliquip ipsum
            aute laboris nisi. Labore labore veniam irure irure ipsum pariatur
            mollit magna in cupidatat dolore magna irure esse tempor ad mollit.
            Dolore commodo nulla minim amet ipsum officia consectetur amet
            ullamco voluptate nisi commodo ea sit eu.
          </div>
        </div>
      </div>
      <div class="col-md-5 py-10 lg:py-0">
        <div
          class="
            py
            border-solid border
            text-2xl
            font-bold
            py-3
            mb-8
            hover:bg-green-500
            hover:text-white
            text-center text-black
            px-4
          "
        >
          Donate Now
        </div>
        <span
          class="w-1/3 lg:px-10 px-4 mr-1 py-4 mt-5 text-white bg-yellow-900"
        >
          #FUNDTAG
        </span>
        <span class="w-2/3 bg-oonpaysecondary py-4 text-white px-8 lg:px-24">
          #Mayjourney21
        </span>
        <div class="row space-y-5 lg:space-y-0 px-3 my-5">
          <div class="col-md-7 border rounded-2xl">
            <h4 class="text-center">Share Now</h4>
            <div class="flex justify-between">
              <span
                class="iconify"
                data-icon="teenyicons:facebook-solid"
                data-inline="false"
              ></span>
              <span
                class="iconify"
                data-icon="ph:instagram-logo-light"
                data-inline="false"
              ></span>
              <span
                class="iconify"
                data-icon="mdi:twitter"
                data-inline="false"
              ></span>
              <span
                class="iconify"
                data-icon="mdi:email-alert"
                data-inline="false"
              ></span>
            </div>
          </div>
          <div
            class="
              col-md-4
              offset-md-1
              flex flex-col
              items-center
              border
              justify-center
              rounded-2xl
            "
          >
            <span
              class="iconify"
              data-icon="mdi:content-copy"
              data-inline="false"
            ></span>
            <small class="text-center">Copy & share FUNDTAG</small>
          </div>
        </div>
        <div class="row px-3 my-5">
          <div class="col-md-12">
            <h5>
              <span class="font-bold">N500,000 raised of </span> N1,000,000
            </h5>
            <p class="text-green-800 text-2xl font-bold">Lagos Nigeria</p>
          </div>
        </div>
        <div class="progress">
          <div
            class="progress-bar bg-success"
            role="progressbar"
            style="width: 50%"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            30%
          </div>
        </div>
        <small>Funding Goal Reached</small>
        <div class="flex py-2 justify-between">
          <p>50 people Supporting</p>
          <p>60 Days Left</p>
        </div>

        <div
          class="
            bg-white
            p-6
            shadow-lg
            rounded-lg
            flex
            justify-between
            items-center
          "
        >
          <div class="flex">
            <div class="mr-4">
              <img
                class="shadow sm:w-12 sm:h-12 w-14 h-14 rounded-full"
                src="http://tailwindtemplates.io/wp-content/uploads/2019/03/link.jpg"
                alt="Avatar"
              />
            </div>
            <div>
              <h3 class="text-xl font-medium text-gray-700">Fundraiser Info</h3>
              <p class="text-gray-600">Robert Honda</p>
            </div>
          </div>
          <div>
            <span class="text-green-400">Verified Account</span>
            <span
              class="iconify"
              data-icon="icon-park-outline:correct"
              data-inline="false"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import TheAppBar from '../../components/NavBar/TheAppBar.vue';
export default {
  components: { TheAppBar },};
</script>

<style  scoped>
.nav-tabs .nav-link {
  border: none;
}

.iconify {
  font-size: 40px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-bottom: 1px solid blue;
}

.iconify[data-icon="teenyicons:facebook-solid"] {
  color: #1eaaf1;
}

.iconify[data-icon="mdi:email-alert"] {
  color: #1eaaf1;
}

.iconify[data-icon="icon-park-outline:correct"] {
  color: green;
}

.iconify[data-icon="ph:instagram-logo-light"] {
  color: #897d4c;
}

small,
.iconify[data-icon="mdi:content-copy"] {
  color: #1eaaf1;
}
</style>